import React, { useState } from "react";
import "./SignUpModal.scss";
import { AiOutlineClose } from "react-icons/ai";
import { subsCribeUser } from "../../utils/api";

const SignUpModal = ({ isOpenSignUp, toggleSignUp }) => {
  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    firstName: "",
    lastName: "",
    password: "",
    gender: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log("Form data:", formData);
    // Clear form fields

    subsCribeUser(formData);
    setFormData({
      email: "",
      mobile: "",
      firstName: "",
      lastName: "",
      password: "",
      gender: "",
    });
    // Close the modal
    toggleSignUp();
  };

  return (
    <>
      {isOpenSignUp && (
        <div className="signup-modal-overlay">
          <div className="signup-container">
            <div className="close-icon" onClick={toggleSignUp}>
              <AiOutlineClose />
            </div>
            <h2>Sign Up</h2>
            <form onSubmit={handleSubmit} className="signup-form">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label htmlFor="mobile">Mobile Number</label>
              <div className="mobile-input">
                <span className="prefix">+91- </span>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  pattern="[0-9]{10}"
                  placeholder="XXXXXXXXXX"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <label htmlFor="gender">Gender</label>
              <select
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="female">Female</option>
                <option value="male">Male</option>
              </select>
              <button type="submit">Continue</button>
              <div className="terms-privacy">
                By continuing, you agree to our
                <a href="#">terms and Use</a> and
                <a href="#">privacy policy</a>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpModal;
