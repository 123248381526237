// Header.jsx
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TbSearch } from "react-icons/tb";
import { CgShoppingCart } from "react-icons/cg";
import { FaUserCircle } from "react-icons/fa";
import { AiOutlineHeart } from "react-icons/ai";
import "./Header.scss";
import Search from "./Search/Search";
import { Context } from "../../utils/context";
import Cart from "../Cart/Cart";
import ModalLogin from "../ModalLogin/ModalLogin";
import SignUpModal from "../SignUpModal/SignUpModal";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalsignUp, setModalsignUp] = useState(false);

  const navigate = useNavigate();
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const { cartCount, showCart, setShowCart } = useContext(Context);

  // Function to scroll to main-content section
  const scrollToMainContent = () => {
    const mainContent = document.getElementById("category-shop");
    // console.log(mainContent);
    if (mainContent) {
      const offset = mainContent.offsetTop; // Get the top offset of the element
      window.scrollTo({ top: offset - 100, behavior: "smooth" });
    }
  };

  return (
    <>
      <header className={`main-header ${scrolled ? "sticky-header" : ""}`}>
        <div className="header-content">
          <ul className="left">
            <li onClick={() => navigate("/")}>Home</li>
            <li onClick={() => navigate("/about")}>About</li>
            <li onClick={scrollToMainContent}>Categories</li>
          </ul>
          <div className="center" onClick={() => navigate("/")}>
            LSA COSEMETICS
          </div>
          <div className="right">
            <FaUserCircle onClick={() => setModal(!modal)} />
            <TbSearch onClick={() => setSearchModal(true)} />
            <AiOutlineHeart />
            <span className="cart-icon" onClick={() => setShowCart(true)}>
              <CgShoppingCart />
              {!!cartCount && <span>{cartCount}</span>}
            </span>
          </div>
        </div>
      </header>
      {searchModal && <Search setSearchModal={setSearchModal} />}
      <ModalLogin
        toggle={() => setModal(!modal)}
        isOpen={modal}
        isToggleSignUp={() => setModalsignUp(true)}
      />
      <SignUpModal
        toggleSignUp={() => setModalsignUp(!modalsignUp)}
        isOpenSignUp={modalsignUp}
      />
      {showCart && <Cart />}
    </>
  );
};

export default Header;
