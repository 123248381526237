import React, { useState } from "react";
import "./ModalLogin.scss";
import { AiOutlineClose } from "react-icons/ai";
import SignUpModal from "../SignUpModal/SignUpModal";

const ModalLogin = ({ isOpen, toggle, isToggleSignUp }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log("Email:", email);
    console.log("Password:", password);
    console.log("Remember Me:", rememberMe);
    // Clear form fields
    setEmail("");
    setPassword("");
    // Close the modal
    toggle();
  };
  const handleSignupClick = () => {
    toggle();
    isToggleSignUp();
  };

  return (
    <>
      {isOpen && (
        <div className="overlay">
          <div className="modal">
            <div className="close-icon" onClick={toggle}>
              <AiOutlineClose />
            </div>
            <h2 style={{ marginBottom: "20px" }}>Login</h2>
            <form onSubmit={handleSubmit} className="form">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="form-links">
                <label>
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />{" "}
                  Remember me
                </label>
                <a href="#">Lost your password?</a>
              </div>
              <button type="submit">Login</button>
              <button
                type="button"
                onClick={handleSignupClick}
                style={{ marginTop: "10px" }}
              >
                Sign up
              </button>
            </form>
          </div>
        </div>
      )}
      {/* Render Signup component when showSignup is true */}
    </>
  );
};

export default ModalLogin;
